<template>
  <div>
    <b-modal ref="my-modal" title="Import checkpoint">
      <template #default>
        <b-form>

          <b-form-group>
            <label>Import Coordinates <span class="text-danger">*</span></label>
            <b-form-file
              v-model="form.file"
              :state="Boolean(form.file)"
              accept=".gpx,.geojson"
              drop-placeholder="Drop file here..."
              placeholder="Choose a file or drop it here..."
            ></b-form-file>
          </b-form-group>


          <b-form-group v-if="form.importType == 'new'">
            <label>Tree Type <span class="text-danger">*</span></label>
            <b-form-select v-model="form.tree_type" :options="treeTypes" required></b-form-select>
          </b-form-group>


          <b-form-group>
            <label>Image</label>
            <b-form-file
              v-model="form.image"
              :state="Boolean(form.image)"
              drop-placeholder="Drop image here..."
              placeholder="Choose an image or drop it here..."
            ></b-form-file>
          </b-form-group>


        </b-form>

        <b-modal id="select-tree-type-modal">
          <template #modal-title>
            Import file
          </template>
          <div v-if="importing">
            <p></p>
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
          <div v-else>
            <b-form-group>
              <label>Choose a tree type <span class="text-danger">*</span></label>
              <b-form-select v-model="form.tree_type" :options="treeTypes" required></b-form-select>
            </b-form-group>
          </div>
          <template #modal-footer="{ ok, cancel, hide, importing }">
            <div v-if="!importing">
              <b-button class="mr-2" variant="primary" @click="importData">
                OK
              </b-button>
              <b-button variant="secondary" @click="hide()">
                Close
              </b-button>
            </div>
          </template>
        </b-modal>
        <b-modal id="select-tree-types-modal">
          <template #modal-title>
            Import file
          </template>
          <div v-if="importing">
            <p></p>
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
          <div v-else>
            <b-form-group>
              <label>Choose tree types <span class="text-danger">*</span></label>
              <b-input-group v-for="ref in Object.keys(form.file_tree_types)" v-bind:key="ref" class="mb-3"
              >
                <template #prepend>
                  <b-input-group-text>{{ form.file_tree_types[ref].reference }} -
                    {{ form.file_tree_types[ref].totalTrees }}
                    Trees
                  </b-input-group-text>
                </template>
                <b-form-select v-model="form.tree_types[ref]" :options="treeTypes" required></b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
          <template #modal-footer="{ ok, cancel, hide, importing }">
            <div v-if="!importing">
              <b-button class="mr-2" variant="primary" @click="importData">
                OK
              </b-button>
              <b-button variant="secondary" @click="hide()">
                Close
              </b-button>
            </div>
          </template>
        </b-modal>
        <b-modal id="loading-modal" hide-footer no-close-on-backdrop no-close-on-esc>
          <template #modal-header>
            Importing file
          </template>
          <div class="d-flex justify-content-center my-4">
            <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </b-modal>

      </template>

      <template #modal-footer>
        <b-button class="mt-3" @click.prevent="onSubmit">Submit</b-button>
        <b-button class="mt-3 ml-2" variant="secondary" @click="hideModal">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Checkpoint from '../../api/Checkpoint'

import xml2js from 'xml2js'

// import {create, all} from 'mathjs'
import {getCenterPoint} from "../../utils/calcCenterPoint";
import Swal from "sweetalert2";
import {ethers} from "ethers";
import Upload from "../../api/Upload";
import hull from 'hull.js'
import * as turf from '@turf/turf'
import {renderTreeTypeData} from "../../utils/renderTreeTypeData";
import Sanitizer from "../../utils/Sanitizer";
import TreeType from "../../api/TreeType";
import Area from "../../api/Area";


// import moment from "moment";

export default {
  name: 'CheckpointModalComponent',
  props: {
    area: {
      required: true,
      type: Object
    }
  },
  watch: {
    'form.file': {
      immediate: false,
      handler: function () {
        const file = this.form.file
        if (file) {
          // read file workflow
          this.readFile(file)

          // fill automatically the name
          const name = file.name
          this.form.name = Sanitizer.sanitizeAlphanumericalCharacters(name.substr(0, name.length - 4))
        }
      }
    },
  },
  data() {
    return {
      importing: false,
      importingStatus: null,
      treeTypes: [],
      form: {
        importType: 'old',
        file: null,
        fileExt: null,
        fileData: null,
        image: null,
        users_permissions_user: null,
        tree_type: null,
        tree_types: [],
        tree_types_ids: [],
        file_tree_types: {},
      },
      parsed: {
        file: null,
        isValid: false
      }
    }
  },
  created() {
    const emptyValue = {value: null, text: 'Please select an option'}

    TreeType.getTreeTypes()
      .then((response) => {
        let treeTypes = []
        treeTypes.push(emptyValue)
        for (let item of response.data) {
          const element = {value: item, text: item.name}
          treeTypes.push(element)
        }
        this.treeTypes = treeTypes
      }).catch((error) => {
      console.log(error)
    })
    this.getWallet()
  },

  methods: {
    showModal() {
      this.resetForm()
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    getWallet() {
      const wallet = ethers.Wallet.createRandom();
      this.form.private_key = wallet.privateKey;
      this.form.wallet_address = wallet.address;
    },
    renderWallet() {
      this.form.wallet_address = '';
      if (this.form.private_key.length === 66) {
        try {
          const wallet = new ethers.Wallet(this.form.private_key)
          this.form.wallet_address = wallet.address;

        } catch (e) {
          Swal.fire('Error', e.message, 'error')
        }
      }
    },
    async onSubmit() {
      if (this.formIsValid()) {


        let image = null;
        if (this.form.image) {
          const formData = new FormData()
          formData.append('files', this.form.image, this.form.image.name)
          image = (await Upload.postUploads(formData)).data[0].id;
        }


        // eslint-disable-next-line no-unreachable
        if (this.parsed.file && this.parsed.isValid) {


          let checkpoint = {
            area: this.area.id,
            image,
            datetime: this.form.datetime,
            data: this.parsed.file,
            qty: 0,
            CO2: 0,
            lat: '',
            lng: '',
            height_average: 0,
            diameter_average: 0,
            theoretical_increment: 0,
          }

          let coordinates = [];
          for (const treeType of this.parsed.file.treeTypes) {
            checkpoint.height_average += treeType.heightAverage / this.parsed.file.treeTypes.length;
            checkpoint.diameter_average += treeType.diameterAverage / this.parsed.file.treeTypes.length;
            checkpoint.qty += parseInt(treeType.qty);
            checkpoint.CO2 += parseFloat(treeType.CO2);
            coordinates = coordinates.concat(treeType.trees.map((tree) => [tree.lng, tree.lat]))
          }

          const centralPoint = getCenterPoint(coordinates)
          checkpoint.lng = centralPoint[0].toString();
          checkpoint.lat = centralPoint[1].toString();
          // debugger // eslint-disable-line no-debugger
          let checkpointData = null;
          if (!this.area.last_checkpoint || this.area.last_checkpoint.token_id) {
            checkpointData = await Checkpoint.postCheckpoints(checkpoint)

          } else {
            console.log(this.area.last_checkpoint, 'this.area.last_checkpoint')
            checkpointData = await Checkpoint.putCheckpoints({...checkpoint, id: this.area.last_checkpoint.id})

            // Todo: delete old theoreticalStat and treeTypes
          }

          let treeTypes = this.parsed.file.treeTypes
            .map(treeType => {
              return {...treeType, checkpoint: checkpointData.data.id};
            });
          await Checkpoint.postCheckpointTreeTypes(treeTypes);
          let theoreticalStats = this.parsed.file.theoreticalStats
            .map(theoreticalStat => {
              return {...theoreticalStat, checkpoint: checkpointData.data.id};
            });
          await Checkpoint.postTheoreticalStats(theoreticalStats);
          console.log(checkpointData)


          Area.putAreas({
            id: this.area.id,
            last_checkpoint: checkpointData.data.id
          })
            .then(() => {

              this.hideModal()
              this.$emit('result', checkpointData)
              this.$toasted.show('The new checkpoint has been added', {
                type: 'success',
                duration: 5000,
                theme: "bubble",
                position: "top-center"
              });


            })


        }
      }
    },
    formIsValid() {
      if (!this.parsed.isValid) {
        Swal.fire('Error', 'You must import your coordinates file', 'error')
        return false;
      }


      return true;
    },
    resetForm() {
      this.form.file = null
      this.form.image = null
      this.form.name = ''
      this.parsed.file = null
      this.parsed.isValid = false
      this.getWallet()
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async importData() {


      // try {

      let importedJSON;
      if (this.form.fileExt == 'gpx') {
        // console.log(this.form.tree_type, 'this.form.tree_type')
        if (!this.form.tree_type) {
          this.form.file = null;
          Swal.fire('Error', 'Tree type is required', 'error')
          return;
        } else {
          this.$bvModal.hide('select-tree-type-modal')
          this.$bvModal.show('loading-modal')
          await this.sleep(100)
          importedJSON = await this.xmlToJSON(this.form.fileData)
        }

      } else if (this.form.fileExt == 'geojson') {
        if (!this.form.tree_types) {
          this.form.file = null;
          Swal.fire('Error', 'Tree types are required', 'error')
        } else {
          this.$bvModal.hide('select-tree-types-modal');
          this.$bvModal.show('loading-modal')
          this.form.importType = 'old';
          importedJSON = await this.geojsonToJSON(this.form.fileData)
        }
      }

      if (!this.form.area_ha) {

        let allAreaTreesCoordinates = [];
        for (const treeType of importedJSON.treeTypes) {
          // const treeType = importedJSON.treeTypes[ref];
          allAreaTreesCoordinates = allAreaTreesCoordinates
            .concat(
              treeType.trees
                .map(tree => [tree.lng, tree.lat])
            );
        }

        let perimeterCoordinates = hull(
          allAreaTreesCoordinates,
          2)

        const coordinatesArea = +(turf.area({
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [
              perimeterCoordinates
            ]
          }
        }) / 10000).toFixed(2);

        this.form.area_ha = coordinatesArea;
      }

      this.parsed.file = importedJSON
      this.parsed.isValid = true

      this.$bvModal.hide('loading-modal');

      // } catch (e) {
      //   Swal.fire('Error', e.message, 'error')
      // }
    },
    readFile() {

      this.file = this.form.file
      const reader = new FileReader()
      reader.onload = async (res) => {

        this.form.fileExt = this.file.name.split('.').at(-1);
        this.form.fileData = res.target.result;
        const supportedExtensions = ['gpx', 'geojson'];
        if (supportedExtensions.includes(this.form.fileExt)) {
          // eslint-disable-next-line no-debugger
          // debugger;
          if (this.form.fileExt == 'gpx') {
            this.$bvModal.show('select-tree-type-modal');
          } else if (this.form.fileExt == 'geojson') {
            const data = JSON.parse(this.form.fileData);
            this.form.file_tree_types = {};
            for (const tree of data.features) {
              let reference = tree.properties.measurements.filter((measurement) => measurement.name == 'Type tree')[0].value;
              this.form.file_tree_types[reference] = this.form.file_tree_types[reference] || {};
              const info = this.form.file_tree_types[reference];
              info.reference = reference;
              info.totalTrees = info.totalTrees ? info.totalTrees + 1 : 1;
            }


            this.$bvModal.show('select-tree-types-modal');
          }
        } else {
          Swal.fire('Error', `We only accept ${supportedExtensions.join(', ')}`, 'error')
        }

      }
      reader.onerror = (err) => console.log(err)
      reader.readAsText(this.file);
    },
    xmlToJSON(str, options) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        // if (!this.form.tree_type) {
        //   try {
        //     await this.getTreeType()
        //   } catch (e) {
        //     reject(e)
        //   }
        // }


        let treeType = {
          id: this.form.tree_type.id,
          reference: this.form.tree_type.reference,
          name: this.form.tree_type.name,
          densityKg: this.form.tree_type.density_kg,
          practicalFormulas: this.form.tree_type.practical_formulas,
          theoreticalFormula: this.form.tree_type.time_formula,
        };

        xml2js.parseString(str, options, (err, jsonObj) => {
          if (err || (!jsonObj.gpx && !jsonObj.gpx.wpt)) {
            return reject(err);
          }
          let trees = jsonObj.gpx.wpt.map((treeInfo) => {
            this.form.datetime = treeInfo.time[0];

            return {
              lat: treeInfo.$.lat,
              lng: treeInfo.$.lon,
              datetime: treeInfo.time[0],
              diameter: 0,
              height: 0,
            }
          })

          treeType.trees = trees;

          this.form.tree_types_ids = [this.form.tree_type.id];

          resolve(renderTreeTypeData([treeType], this.form.datetime));

        })

      });
    },
    geojsonToJSON(str) {
      const data = JSON.parse(str);
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        for (const ref of Object.keys(this.form.tree_types)) {
          this.form.tree_types_ids.push(this.form.tree_types[ref].id)
        }

        let groupTreeTypes = {}
        let treeType = {};

        for (const tree of data.features) {
          let reference = tree.properties.measurements.filter((measurement) => measurement.name == 'Type tree')[0].value;
          let datetime = tree.properties.measurementTime;
          let lng = tree.geometry.coordinates[0];
          let lat = tree.geometry.coordinates[1];
          let heightFilter = tree.properties.measurements.filter((measurement) => measurement.name === 'H/m')[0];
          let diameterFilter = tree.properties.measurements.filter((measurement) => measurement.name === 'D/cm')[0];
          let height = parseFloat(heightFilter ? heightFilter.value : 0);
          let diameter = parseFloat(diameterFilter ? diameterFilter.value : 0);

          // treeType = treeType.reference !== reference ? (await TreeType.getTreeTypesByRef(reference)).data[0] : treeType;
          treeType = treeType.reference !== reference ? this.form.tree_types[reference] : treeType;

          if (groupTreeTypes[reference] == undefined) {
            // if (treeType && treeType.id && !this.form.tree_types_ids.includes(treeType.id)) {
            //   this.form.tree_types_ids.push(treeType.id)
            // } else {
            //   this.$bvModal.hide('loading-modal')
            //   reject(`Could not find tree type with reference ${reference}`);
            //   return;
            // }

            this.form.datetime = datetime;

            groupTreeTypes[reference] = {
              id: treeType.id,
              reference,
              name: treeType.name,
              densityKg: treeType.density_kg,
              practicalFormulas: treeType.practical_formulas,
              theoreticalFormula: treeType.time_formula,
              trees: []
            }
          }

          groupTreeTypes[reference].trees.push({
            datetime,
            height,
            diameter,
            lng,
            lat
          })
        }
        this.$bvModal.hide('loading-modal')
        console.log(this.$bvModal, 'this.$bvModal')
        console.log({groupTreeTypes})
        resolve(renderTreeTypeData(Object.values(groupTreeTypes), this.form.datetime));
      });
    },
    getTreeType() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {


        this.$bvModal.msgBoxConfirm('Please select a tree type.', {
          title: 'Please Confirm',
          okTitle: 'OK',
          cancelTitle: 'Close',
          // footerClass: 'p-2',
          hideHeaderClose: true
        })
          .then(value => {
            if (value && this.form.tree_type) {
              this.form.importType = 'new';
              resolve(this.form.tree_type);
            } else {
              this.form.importType = 'old';
              this.form.file = null;
              reject('Tree type is required')
            }
          })
          .catch(err => {
            reject(err);
          })


      })
    }
  }
}
</script>
