const Sanitizer = {
  sanitize: (stringToSanitize, pattern, flags = '', replacement = '') => {
    return stringToSanitize.replace(new RegExp(pattern, flags), replacement)
  },

  sanitizeDiacriticalMarks: (stringToSanitize) => {
    const map = {
      '-': ' |_|-',
      'a': 'á|à|ã|â|À|Á|Ã|Â',
      'e': 'é|è|ê|É|È|Ê',
      'i': 'í|ì|î|Í|Ì|Î',
      'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
      'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      'c': 'ç|Ç',
      'n': 'ñ|Ñ'
    }

    Object.keys(map).forEach((pattern) => {
      stringToSanitize = Sanitizer.sanitize(stringToSanitize, map[pattern], 'g', pattern)
    })

    return stringToSanitize
  },

  sanitizeSlashCharacters: (stringToSanitize) => {
    return Sanitizer.sanitize(stringToSanitize, '\\/\\/+', 'g', '/')
  },

  sanitizeAlphanumericalCharacters: (stringToSanitize) => {
    return Sanitizer.sanitize(stringToSanitize, '[^A-Za-z0-9]+', 'g')
  },

  sanitizeAlphabeticalCharacters: (stringToSanitize) => {
    return Sanitizer.sanitize(stringToSanitize, '[^A-Za-z]+', 'g')
  },

  sanitizeNumericalCharacters: (stringToSanitize) => {
    return Sanitizer.sanitize(stringToSanitize, '[^0-9]+', 'g')
  },

  stripHtmlTags: (stringToSanitize) => {
    return Sanitizer.sanitize(stringToSanitize, '<\\/?[^>]+>', 'gi')
  }
}

export default Sanitizer
