<template>
  <b-container class="area-item" fluid>
    <Transition name="fade">
      <b-row v-if="area" class="">
        <b-col class="area-info mb-3" lg="2" md="3">
          <img v-if="area.image" :src="apiUrl+area.image" alt="" class="mb-2 mw-100 rounded">
          <a
            v-if="area.token_id && !area.deleted_token"
            :href="'https://opensea.io/assets/matic/'+area.nft_smart_contract+'/'+area.token_id"
            class="btn btn-secondary btn-block d-block d-md-none mb-2"
            target="_blank">View NFT<i
            class="fa-solid fa-arrow-up-right-from-square ml-1"></i></a>
          <b-card class="bg-primary mb-2 px-3 py-2" no-body>
            <div class="font-weight-bold">Forest
              <b-badge v-if="area.area_ha" class="float-right" variant="secondary">Area:
                {{
                  area.area_ha ? new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(area.area_ha) + ' Ha' : ''
                }}
              </b-badge>
            </div>
            <div>{{ area.name }}</div>
            <div class="font-weight-bold">Wallet</div>
            <div>
              <a :href="explorerUrl+'/address/'+area.wallet_address"
                 class="text-white" target="_blank">{{
                  this.textShort(area.wallet_address)
                }}</a>
            </div>
            <div class="font-weight-bold">Balance.</div>
            <div>
              <a v-if="balance != null && config"
                 :href="explorerUrl+'/token/'+config.token_contract_address+'?a='+area.wallet_address"
                 class="text-white" target="_blank">{{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4
                  }).format(balance) + ' ZCO2'
                }}</a>
              <i v-else class="fa-solid fa-spinner fa-spin-pulse"></i>

              <button v-if="balance != null &&
            area.user_id && getWeb2ActiveAccount.id === area.user_id &&
            getWeb2ActiveAccount &&
            (
                getWeb2ActiveAccount.role.name === 'Administrator' ||
                getWeb2ActiveAccount.role.name === 'Authenticated'
            )
" class="btn badge float-right badge-secondary ml-2" @click="withdraw">Withdraw
              </button>
            </div>
            <button
              v-if="!area.user_name && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
              class="btn btn-sm btn-secondary btn-block mt-2"
              @click="changeOwner">Set owner
            </button>
            <button
              v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
              class="btn btn-sm btn-secondary btn-block mt-2"
              @click="addAdditionality">Add additionality
            </button>
            <div v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'">
              <div
                v-for="(additionality, i) of area.additionalities" :key="'additionality'+i">
                <i @click="delAdditionality(i)" role="button"
                   class="fa-solid fa-xmark fa-xs"></i>
                {{
                  additionality.type
                }}

              </div>
            </div>
            <div v-if="area.user_name"
                 class="font-weight-bold">Owner
            </div>
            <div v-if="area.user_name">
              {{ area.user_name }} <i
              v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
              class="far fa-edit fa-xs"
              role="button" @click="changeOwner"></i>
            </div>
          </b-card>
          <b-card class="bg-primary mb-2 px-3 py-2" no-body>
            <div class="row">
              <div class="col-md-12">
                <div class="font-weight-bold">Mean Density</div>
                <div>{{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(area.qty / area.area_ha)
                  }} Trees/Ha
                </div>
              </div>
              <div class="col-md-12">
                <div class="font-weight-bold">Mean Anual Increase</div>
                <div>
                  {{
                    new Intl.NumberFormat('de-DE', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(currentYearTheoreticalIncrement / area.area_ha)
                  }}
                  Ton CCO2/Year Ha
                </div>
              </div>
            </div>

          </b-card>
          <b-card
            v-if="area.lng && area.lat"
            class="bg-primary mb-2 px-3 py-2" no-body>
            <div class="font-weight-bold">GPS Location <span
              v-if="area.country">({{ area.country }})</span> <a
              :href="'https://www.google.com/maps/place/'+area.lat+','+area.lng"
              class="text-white float-right"
              target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </div>
            <div class="d-none">
              <a :href="'https://www.google.com/maps/place/'+area.lat+','+area.lng"
                 class="text-white"
                 target="_blank">Lat: {{ area.lat }}<br>Lng:
                {{ area.lng }}</a>
            </div>
          </b-card>

          <b-card class="bg-primary mb-2 px-3 py-2" no-body>
            <div class="font-weight-bold">Tree Types
              <b-badge class="float-right" variant="dark">
                {{ new Intl.NumberFormat('de-DE').format(area.qty) }}
              </b-badge>
            </div>
            <div v-for="(treeType, i) of area.treeTypes" :key="'A'+treeType.id+'T'+treeType.qty">{{
                treeType.name
              }}
              <i v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
                 class="far fa-edit fa-xs"
                 role="button" @click="changeTreeType(i)"></i>
              <b-badge :style="{
        backgroundColor: treeType.color
      }" class="float-right">{{ new Intl.NumberFormat('de-DE').format(treeType.qty) }}
              </b-badge>
            </div>
          </b-card>
          <b-card v-if="area.year" class="bg-primary mb-2 px-3 py-2 d-none" no-body>
            <div class="font-weight-bold">Blockchain Timestamp</div>
            <div>{{ area.year }}</div>
          </b-card>
          <b-card v-if="area.cadastre || area.cadastre_certificate" class="bg-primary mb-2 px-3 py-2" no-body>
            <div class="font-weight-bold">Cadastre</div>
            <div v-if="area.url"><a :href="area.url" class="text-white" target="_blank">{{ area.cadastre }}</a>
            </div>
            <div v-if="!area.url">{{ area.cadastre }}</div>
            <div v-if="area.cadastre_certificate">
              <a :href="apiUrl+area.cadastre_certificate" class="text-white"
                 target="_blank">Certificate</a>
            </div>
          </b-card>
          <b-card v-if="area.insurance_certificate" class="bg-primary mb-2 px-3 py-2" no-body>
            <div class="font-weight-bold">Insurance</div>
            <div v-if="area.insurance_certificate"><a :href="apiUrl+area.insurance_certificate"
                                                      class="text-white"
                                                      target="_blank">Certificate</a></div>
          </b-card>
          <b-card v-if="area.fertilizing_certificate" class="bg-primary mb-2 px-3 py-2" no-body>
            <div class="font-weight-bold">Fertilizing</div>
            <div v-if="area.fertilizing_certificate"><a :href="apiUrl+area.fertilizing_certificate"
                                                        class="text-white"
                                                        target="_blank">Certificate</a></div>
          </b-card>
          <b-card v-if="area.needle_analyze || area.project_duration || area.identification_code"
                  class="bg-primary mb-2 px-3 py-2" no-body>
            <div v-if="area.identification_code" class="font-weight-bold">Identification code</div>
            <div v-if="area.identification_code && area.contract">
             <a :href="apiUrl+area.contract"
                                                        class="text-white"
                target="_blank"> {{ area.identification_code }}</a>
            </div>
            <div v-if="area.identification_code && !area.contract">
              {{ area.identification_code }}
            </div>
            <div v-if="area.additionality_type" class="font-weight-bold">Type of additionality</div>
            <div v-if="area.additionality_type">
              {{ area.additionality_type }}
            </div>
            <div v-if="area.project_duration" class="font-weight-bold">Project duration</div>
            <div v-if="area.project_duration">
              {{ area.project_duration }} years
            </div>
            <div v-if="area.needle_analyze" class="font-weight-bold">Needle analyze</div>
            <div v-if="area.needle_analyze">
              <a :href="apiUrl+area.needle_analyze" class="text-white" target="_blank">Certificate</a>
            </div>
          </b-card>
          <img v-if="area.additionality_certificate" :src="apiUrl+area.additionality_certificate" alt=""
               class="mb-2 mw-100 rounded">

          <router-link class="btn btn-secondary" to="/areas">View all areas</router-link>
        </b-col>
        <b-col id="map-chart" class="mb-3" lg="3" md="4">
          <div class="h-50 pb-3">
            <Transition name="fade">
              <MapBoxItemComponent v-if="area.last_checkpoint != undefined" :areaData="area.last_checkpoint.data"/>
            </Transition>
            <div v-if="area.last_checkpoint == undefined" style="background: #2e4032;"
                 class="card h-100 justify-content-center align-items-center">
              <b-spinner></b-spinner>
            </div>
          </div>
          <div class="h-50">
            <line-chart :chart-data="timeFormulaChartData" :options="timeFormulaChartOptions"
                        :styles="myStyles"></line-chart>
          </div>

        </b-col>
        <b-col lg="7" md="5">
          <b-card class="bg-primary px-3 pt-2 pb-3" no-body>
            <h4 class="mb-0">Theoretical (ex-ante): Blockchain NFT *</h4>
            <p>Total calculated CO2 in tones for this forest</p>
          </b-card>
          <b-table :fields="theoreticalFormulaStatsFields" :items="theoreticalFormulaStats" sticky-header="388px">
            <template #head(index)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(year)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(totalTonCCO2)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(theoreticalIncrement)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(theoreticalIncrementProfit)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(theoreticalIncrementAcumProfit)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(totalAdditionalTonCCO2)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(theoreticalAdditionalIncrement)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #cell(index)="data">
              <div class="text-right">
                {{ data.item.index }}
              </div>
            </template>
            <template #cell(year)="data">
              <div class="text-right">
                {{ data.item.year }}
              </div>
            </template>
            <template #cell(totalTonCCO2)="data">
              <div class="text-right">
                {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(data.item.baseline_value &lt; 0 ? 0: data.item.totalTonCCO2)
                }}
              </div>
            </template>
            <template #cell(theoreticalIncrement)="data">
              <div class="text-right">
                {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(data.item.baseline_value &lt; 0 ? 0 : data.item.theoreticalIncrement)
                }}
              </div>
            </template>
            <template #cell(theoreticalIncrementProfit)="data">
              <div class="text-right">
                {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(data.item.theoreticalIncrementProfit)
                }}€
              </div>
            </template>
            <template #cell(theoreticalIncrementAcumProfit)="data">
              <div class="text-right">
                {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(data.item.theoreticalIncrementAcumProfit)
                }}€
              </div>
            </template>
            <template #cell(totalAdditionalTonCCO2)="data">
              <div class="text-right">
                {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(data.item.totalAdditionalTonCCO2)
                }}
              </div>
            </template>
            <template #cell(theoreticalAdditionalIncrement)="data">
              <div class="text-right">
                {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(data.item.theoreticalAdditionalIncrement)
                }}
              </div>
            </template>
          </b-table>
          <b-card class="bg-primary px-3 py-2" no-body>
            <h4 class="mb-0">Real (ex-post): Centralized Database *
              <b-button
                v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator' && !area.token_id && !area.deleted_token"
                class="float-right" variant="secondary" @click="createNewToken()">Create
                NFT
              </b-button>
              <b-button
                v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator' && area.token_id && !area.deleted_token"
                class="float-right"
                variant="danger" @click="deleteToken">Delete NFT
              </b-button>
              <b-button
                v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
                :disabled="area.token_id && !area.deleted_token"
                class="float-right mr-2"
                variant="secondary" @click="showCheckpointModal">
                Import
              </b-button>
            </h4>
          </b-card>
          <!-- Using value -->
          <b-table :fields="practicalStatsFields" :items="practicalStats" sticky-header="388px">
            <template #head(date)="scope">
              <div class="text-center">
                {{ scope.label }}
              </div>
            </template>
            <template #head(avgDiameter)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(avgHeight)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(tonCCO2)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #head(treeQty)="scope">
              <div class="text-right">
                {{ scope.label }}
              </div>
            </template>
            <template #cell(date)="data">
              <div class="text-center">
                <p class="mb-0">{{ data.item.date }}</p>
                <!--              <a v-if="data.item.tokenIdUrl" :href="data.item.tokenIdUrl" target="_blank"-->

                <button v-if="data.item.contractAddress && data.item.tokenId"
                        v-b-modal="'nft-modal-'+data.item.tokenId"
                        class="btn btn-primary btn-sm mt-1">
                  NFT<i class="fa-solid fa-arrow-up-right-from-square ml-1"></i>
                </button>
                <b-modal :id="'nft-modal-'+data.item.tokenId" size="lg">
                  <template #modal-title>
                    <h3 class="d-inline">NFT INFO</h3>
                    <h4 v-if="!data.item.deletedToken"
                        class="badge badge-success d-inline-block mb-0 ml-2">Active</h4>
                    <h4 v-if="data.item.deletedToken"
                        class="badge badge-danger d-inline-block mb-0 ml-2">Inactive</h4>
                  </template>
                  <div class="text-center">
                    <b-tabs content-class="mt-3" justified>
                      <b-tab active title="Information">
                        <div class="row">
                          <div class="col-md-5">
                            <img v-if="area.image" :alt="data.item.name"
                                 :src="apiUrl+area.image" class="w-100 mb-3">
                          </div>
                          <div class="col-md-7">
                            <p class="font-weight-bold mb-0">Name</p>
                            <p>{{ data.item.name }}</p>
                            <p class="font-weight-bold mb-0">Chain</p>
                            <p>{{ data.item.chain }}</p>
                            <p class="font-weight-bold mb-0">Smart Contract</p>
                            <p>{{ data.item.contractAddress }}</p>
                            <p class="font-weight-bold mb-0">Token ID {{
                                data.item.tokenId
                              }}</p>
                            <a :href="data.item.tokenIdUrl" class="mb-3 d-block"
                               target="_blank">
                              View on Explorer<i
                              class="fa-solid fa-arrow-up-right-from-square ml-1"></i>
                            </a>
                            <div v-if="!data.item.deletedToken">
                              <p class="font-weight-bold mb-0">OpenSea</p>
                              <a
                                :href="'https://opensea.io/assets/matic/'+data.item.contractAddress+'/'+data.item.tokenId"
                                target="_blank"
                              >
                                View on OpenSea<i
                                class="fa-solid fa-arrow-up-right-from-square ml-1"></i>
                              </a>
                            </div>
                          </div>
                        </div>

                      </b-tab>
                      <b-tab title="Blockchain Stats">
                        <b-table :items="blockchainTheoreticalStats" head-variant="dark"></b-table>

                      </b-tab>

                    </b-tabs>


                  </div>
                  <template #modal-footer="{ ok }">
                    <b-button class="mx-auto" variant="primary" @click="ok()">
                      OK
                    </b-button>
                  </template>
                </b-modal>

              </div>

            </template>
            <template #cell(avgDiameter)="data">
              <div v-for="(treeType, i) of data.item.treeTypes" :key="'B'+i" class="text-right">
                <b>{{ treeType.name }}:</b> {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(treeType.diameterAverage || 0)
                }}
              </div>
            </template>
            <template #cell(avgHeight)="data">
              <div v-for="(treeType, i) of data.item.treeTypes" :key="'C'+i" class="text-right">
                <b>{{ treeType.name }}:</b> {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(treeType.heightAverage || 0)
                }}
              </div>
            </template>
            <template #cell(tonCCO2)="data">
              <div v-for="(treeType, i) of data.item.treeTypes" :key="'D'+i" class="text-right">
                <b>{{ treeType.name }}:</b> {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(treeType.CO2 || 0)
                }}
              </div>
              <div class="total text-right font-weight-bold">
                Total: {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(data.item.totalTonCCO2)
                }}
              </div>
            </template>
            <template #cell(treeQty)="data">

              <div v-for="(treeType, i) of data.item.treeTypes" :key="'E'+i" class="text-right">
                <b>{{ treeType.name }}:</b>
                {{ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(treeType.qty || 0) }}
              </div>
              <div class="total text-right font-weight-bold">
                Total: {{
                  new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(data.item.totalTreeQty)
                }}
              </div>
            </template>
          </b-table>


          <small>(1) Only trunk biomass<br>(2) Total biomass: truck + branches + leaves + roots</small>

        </b-col>
      </b-row>
    </Transition>
    <div v-if="!area" class="card justify-content-center align-items-center"
         style="background: #2e4032;height: calc(100vh - 70px);">
      <b-spinner></b-spinner>
    </div>
    <CheckpointModalComponent v-if="area && area.id" ref="checkpointModal" :area="area" @result="loadArea"/>
  </b-container>
</template>

<script>
import MapBoxItemComponent from '../../components/Area/MapBoxItemComponent'
import Area from '../../api/Area'
import LineChart from "../../components/Chart/LineChart.js";
import moment from 'moment';
// import Ajax from "../../api/Ajax";
import Swal from "sweetalert2";
import TreeType from "../../api/TreeType";
import {mapActions, mapGetters} from "vuex";
import Checkpoint from "../../api/Checkpoint";
// import {create, all} from 'mathjs'
import {textShortener} from "../../utils/textShortener";
import {renderTreeTypeData} from "../../utils/renderTreeTypeData";
import User from "../../api/User";
import Config from "../../api/Config";
import CheckpointModalComponent from "../../components/Checkpoints/CheckpointModalComponent";
// import CheckpointTreeTypes from "../../api/Checkpoint";
import Additionalities from "@/api/Additionality";
import Fertilizings from "@/api/Fertilizing";

export default {
  name: 'AreaItem',

  components: {
    MapBoxItemComponent,
    CheckpointModalComponent,
    LineChart
  },
  computed: {
    ...mapGetters("user", ["isWeb2UserConnected", "getWeb2ActiveAccount"]),
    ...mapGetters("tokenContract", ["getTokenBalance"]),
    ...mapGetters("accounts", ["getActiveAccount"]),
    myStyles() {
      return {
        height: '100%',
        position: 'relative'
      }
    }
  },

  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      explorerUrl: process.env.VUE_APP_EXPLORER_URL,
      NFTModalTabIndex: 1,
      balance: null,
      config: null,
      area: null,
      nft: false,
      currentYearTheoreticalIncrement: null,
      allTreeTypes: [],
      users: [],
      areaTreeTypes: [],
      blockchainTheoreticalStats: [],
      theoreticalFormulaStats: [],
      theoreticalFormulaStatsFields: [
        {
          key: 'index',
          label: 'Index',
        },
        {
          key: 'year',
          label: 'Farming Year',
        },
        {
          key: 'totalTonCCO2',
          label: 'Ton CCO2 Acum. (1)',
        },
        {
          key: 'theoreticalIncrement',
          label: 'Ton CCO2 Yearly Incr.',
        },
          {
          key: 'totalAdditionalTonCCO2',
          label: 'Ton CCO2 Additionaly Acum.',
        },
        {
          key: 'theoreticalAdditionalIncrement',
          label: 'Ton CCO2 Additionaly Yearly Incr.',
        }
      ],
      practicalStats: [],
      practicalStatsFields: [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'avgDiameter',
          label: 'Avg D (cm)',
        },
        {
          key: 'avgHeight',
          label: 'Avg H (m)',
        },
        {
          key: 'tonCCO2',
          label: 'Ton CCO2 Acum. (2)',
        },
        {
          key: 'treeQty',
          label: 'Tree Qty',
        },
      ],
      timeFormulaChartData: null,
      timeFormulaChartOptions: null,
    }
  },

  created() {

    this.loadArea();

    Config.getConfig()
      .then((res) => {
        this.config = res.data;
      });

    User.getUsers()
      .then((res) => {
        this.users = res.data;
      });

    TreeType.getTreeTypes()
      .then((res) => {
        this.allTreeTypes = res.data;
      })
  },
  methods: {
    ...mapActions("NFTcontract", ["createNftToken", "deleteNftToken"]),
    ...mapActions("tokenContract", ["updateBalance"]),
    // ...mapActions("tokenContract", ["createNftToken", "deleteNftToken"]),
    showCheckpointModal() {
      this.$refs.checkpointModal.showModal();
    },
    async loadArea() {
      // const area = await Area.getAreas(this.$route.params.id)
      const area = await Area.getAreaInfo(this.$route.params.id)
      this.area = area.data;

      try {

        Checkpoint.getCheckpoints(this.area.last_checkpoint_id)
          .then((res) => {
            this.area.last_checkpoint = res.data;
          })

        Area.getBalance(this.area.id).then((res) => {

          this.balance = res.data
          this.updateBalance();
        })


        Area.getAreasPrivateKey(this.$route.params.id).then((res) => {

          this.area.privateKey = res.data.private_key;
          this.$store.dispatch('NFTcontract/fetchNftContract', this.area.privateKey)
        })
      } catch (e) {
        console.log(e, 'e')
      }

      if (this.area?.last_checkpoint_id) {
        let areaLastCheckpointData = this.area.treeTypes;
        console.log({areaLastCheckpointData})


        // for (const treeType of areaLastCheckpointData) {
        //   // const treeType = areaLastCheckpointData.treeTypes[ref];
        //
        //   TreeType.getTreeTypes(treeType.id).then((res) => {
        //     const dbTreeType = res.data;
        //     treeType.color = dbTreeType.color;
        //     console.log(this.area.treeTypes, 'this.area.treeTypes2')
        //   })
        // }
        this.renderStatsTable()
      }
    },
    createNewToken() {
      if (this.theoreticalFormulaStats.length >= 10) {
        Swal.fire({
          title: 'Are you sure?',
          html: `Once you have created the NFT, you won't be able to import or modify the information from this forest.`,
          icon: 'warning',
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Processing',
              html: 'Waiting for confirmations',
              didOpen: () => {
                Swal.showLoading()
              },
              allowOutsideClick: () => !Swal.isLoading()
            })
            this.createNftToken(
              {
                walletAddress: this.area.wallet_address,
                scanDt: moment(this.area.datetime).format('X'),
                tonCO2Year1: this.theoreticalFormulaStats[0].theoreticalIncrement,
                tonCO2Year2: this.theoreticalFormulaStats[1].theoreticalIncrement,
                tonCO2Year3: this.theoreticalFormulaStats[2].theoreticalIncrement,
                tonCO2Year4: this.theoreticalFormulaStats[3].theoreticalIncrement,
                tonCO2Year5: this.theoreticalFormulaStats[4].theoreticalIncrement,
                tonCO2Year6: this.theoreticalFormulaStats[5].theoreticalIncrement,
                tonCO2Year7: this.theoreticalFormulaStats[6].theoreticalIncrement,
                tonCO2Year8: this.theoreticalFormulaStats[7].theoreticalIncrement,
                tonCO2Year9: this.theoreticalFormulaStats[8].theoreticalIncrement,
                tonCO2Year10: this.theoreticalFormulaStats[9].theoreticalIncrement,
              }
            )
              .then((res) => {
                const data = {
                  id: this.area.last_checkpoint_id,
                  nft_smart_contract: res.contractAddress,
                  token_id: res.tokenId,
                  token_id_url: res.tokenIdUrl,
                  tx: res.tx,
                  tx_url: res.txUrl,
                  chain: res.chain,
                };
                Checkpoint.putCheckpoints(data)
                  .then(() => {
                    this.loadArea();
                    // this.area.last_checkpoint = {
                    //   ...this.area.last_checkpoint,
                    //   ...data
                    // }
                    this.renderStatsTable();

                    Swal.fire(
                      'NFT successfully created',
                      `You've successfully created the NFT with the <a href="${res.tokenIdUrl}" target="_blank">token ID ${res.tokenId}</a>`,
                      'success');
                  })
              })
              .catch((e) => {
                Swal.fire({
                  title: 'Error',
                  html: e.message,
                  icon: 'error',
                  didOpen: () => {
                    Swal.hideLoading()
                  },
                })
              })
          }
        })

      } else {
        Swal.fire(
          'Error',
          'There was an error with Theoretical info',
          'error'
        )
      }

    },
    deleteToken() {
      Swal.fire({
        title: 'Are you sure?',
        html: `You will burn this NFT, this means that you won't be the owner of this NFT anymore but you will be able to import or modify the information from this forest.`,
        icon: 'warning',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Processing',
            html: 'Waiting for confirmations',
            didOpen: () => {
              Swal.showLoading()
            },
            allowOutsideClick: () => !Swal.isLoading()
          })
          this.deleteNftToken(this.area.token_id)
            .then((res) => {
              const data = {
                id: this.area.last_checkpoint_id,
                deleted_token: 1,
              };
              Checkpoint.putCheckpoints(data)
                .then(() => {
                  // Todo: why i use this? need to correct
                  this.area.last_checkpoint = {
                    ...this.area.last_checkpoint,
                    ...data
                  }
                  Swal.fire(
                    'NFT successfully deleted',
                    `You've successfully deleted the NFT with the <a href="${res.tokenIdUrl}" target="_blank">token ID ${res.tokenId}</a>`,
                    'success');
                })
            })
            .catch((e) => {
              Swal.fire({
                title: 'Error',
                html: e.message,
                icon: 'error',
                didOpen: () => {
                  Swal.hideLoading()
                }
              })
            })
        }
      })

    },
    async renderStatsTable() {
      let areaLastCheckpointTotalCO2 = this.area.CO2Comparison;
      this.practicalStats = [];
      const checkpoints = [(await Checkpoint.getCheckpoints(this.area.last_checkpoint_id)).data];


      for (const checkpoint of checkpoints) {
        let avgDiameter = [];
        let avgHeight = [];
        let tonCCO2 = [];
        let treeQty = [];

        for (const treeType of checkpoint.data.treeTypes) {
          avgDiameter.push(`${treeType.name}: ${treeType.diameterAverage.toFixed(2)}`);
          avgHeight.push(`${treeType.name}: ${treeType.heightAverage.toFixed(2)}`);
          tonCCO2.push(`<b>${treeType.name}</b>: ${(treeType.CO2 || 0).toFixed(2)}`);
          treeQty.push(`${treeType.name}: ${treeType.qty}`);
        }

        this.practicalStats.push({
          date: moment(checkpoint.datetime).format('DD/MM/YYYY'),
          tokenIdUrl: checkpoint.token_id_url,
          tokenId: checkpoint.token_id,
          name: this.area.name,
          contractAddress: checkpoint.nft_smart_contract,
          chain: checkpoint.chain,
          tx: checkpoint.tx,
          txUrl: checkpoint.tx_url,
          deletedToken: checkpoint.deleted_token,
          avgDiameter,
          avgHeight,
          tonCCO2,
          treeTypes: checkpoint.data.treeTypes,
          totalTonCCO2: (checkpoint.CO2 || 0).toFixed(5),
          treeQty,
          totalTreeQty: checkpoint.qty
        })
      }


      this.practicalStats.reverse()

      this.theoreticalFormulaStats = [];
      let datasets = [];
      let annotations = [];
      let labels = [];

      if (this.area.last_checkpoint_id) {
        let data = [];

        // let dataAdditional = [];
        let dataAdditionalOptional = {};
        let baselineAdditional = {};
        let theoreticalIncrementAcumProfit = 0;


        for (const j in this.area.theoreticalStats) {
          const theoreticalStat = this.area.theoreticalStats[j]

          if (theoreticalStat.year >= moment().format('YYYY')) {


            this.theoreticalFormulaStats.push({
              ...theoreticalStat,
              theoreticalIncrementAcumProfit,
            })


            if (this.blockchainTheoreticalStats.length < 10) {
              this.blockchainTheoreticalStats.push({
                year: theoreticalStat.year,
                'Yearly Increment': new Intl.NumberFormat('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(theoreticalStat.theoreticalIncrement) + ' Ton CCO2',
              })
            }
          }


          for (const additionality of this.area.additionalities) {

            if (!baselineAdditional[additionality.type]) {
              baselineAdditional[additionality.type] = []
            }

            if (!dataAdditionalOptional[additionality.type]) {
              dataAdditionalOptional[additionality.type] = []
            }

            baselineAdditional[additionality.type][j] = baselineAdditional[additionality.type][j] || 0;
            dataAdditionalOptional[additionality.type][j] = dataAdditionalOptional[additionality.type][j] || null;
            console.log({additionality})
            // let found = false;
            for (const i in additionality.values) {
              const value = additionality.values[i];

              // console.log(additionality.type+' theoreticalStat.year '+theoreticalStat.year+' == (value.year '+value.year+' + additionality.starting_year '+additionality.starting_year+') '+(value.year + additionality.starting_year))
              if (theoreticalStat.year == (value.year + additionality.starting_year)) {
                dataAdditionalOptional[additionality.type][j] = (dataAdditionalOptional[additionality.type][j] || 0) + value.value;
                baselineAdditional[additionality.type][j] = (baselineAdditional[additionality.type][j] || 0) + value.baseline_value;

                // found = true;
              }


            }

            // if (!found) {
            //   dataAdditionalOptional[additionality.type][j] = null;
            // }


          }


          labels.push(theoreticalStat.index + ' Years');
          data.push(theoreticalStat.totalTonCCO2.toFixed(2))


          // dataAdditional.push(tonCCO2AdditionalRequiredPerTree.toFixed(5));

        }


        console.log({dataAdditionalOptional})
        console.log({baselineAdditional})

        console.log({data1: data})

        for (const i in baselineAdditional) {

          const additionality = baselineAdditional[i];
          console.log({additionality})

          for (const j in additionality) {

            if (additionality[j]) {
              data[j] = parseFloat(data[j]) + additionality[j];
            }

          }

        }

        console.log({data2: data})


        const currentStats = this.theoreticalFormulaStats.filter(({year}) => year === parseInt(moment().format('YYYY')))[0];
        this.currentYearTheoreticalIncrement = parseFloat(currentStats.theoreticalIncrement);

        datasets.push({
          label: 'Ton CCO2',
          borderColor: '#4b6b41',
          backgroundColor: '#4b6b41',
          borderWidth: 3,
          hoverradius: 0,
          radius: 0,
          fill: false,
          data
        })

        for (const i in dataAdditionalOptional) {
          const data = dataAdditionalOptional[i];

          datasets.push({
            label: `Additional ${i}`,
            borderColor: '#628d3d',
            backgroundColor: '#628d3d',
            borderWidth: 3,
            hoverradius: 0,
            radius: 0,
            fill: false,
            data
          })
        }

        console.log({areaLastCheckpointTotalCO2})


        annotations.push({
          drawTime: 'afterDraw', // overrides annotation.drawTime if set
          id: 'a-line-' + (annotations.length + 1), // optional
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: areaLastCheckpointTotalCO2,
          borderColor: '#ffdd00',
          borderWidth: 2,
        })

//         annotations.push({
//           type: 'box',
//           drawTime: 'beforeDatasetsDraw',
//           id: 'a-box-1',
//           xScaleID: 'x-axis-0',
//           yScaleID: 'y-axis-0',
//           xMin: 25,
//           xMax: 60,
//           yMin: 0,
//           borderColor: '#16311c9e',
//           backgroundColor: '#16311c9e',
//         })
      }
      this.renderTimeFormulaChart(labels, datasets, annotations);

    },
    renderTimeFormulaChart(labels, datasets, annotations) {

      this.timeFormulaChartData = {
        labels,
        datasets
      }

      this.timeFormulaChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {

              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(tooltipItem.yLabel);
              return label;
            },

            footer: (tooltipItems, data) => {
              if (tooltipItems === 1) {
                console.log({tooltipItems: "1" || tooltipItems})
                console.log({data: "1" || data})
              }

              let sumBaseLine = 0;
              let sumAdditionality = 0;
              for (const i in tooltipItems) {
                const tooltipItem = tooltipItems[i];
                if (data.datasets[i].label.includes('Additional'))
                  sumAdditionality += tooltipItem.yLabel;
                else {
                  sumBaseLine += tooltipItem.yLabel;
                }
              }
              return `T. Additional: ${Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(sumAdditionality)}\nT. Base Line: ${Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(sumBaseLine)}
`;
//               return ``;
            }
          }
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: function (label) {
                  return new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(label);
                }
              }
            }
          ]
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
        annotation: {
          drawTime: 'afterDatasetsDraw',
          annotations
        }
      }
    },
    async changeTreeType(i) {
      let oldTreeType = {...this.area.treeTypes[i]};

      let inputOptions = this.allTreeTypes.reduce((json, val) => {
        json[val.id] = val.name;
        return json;
      }, {})

      const {value: treeTypeId} = await Swal.fire({
        title: `Change ${oldTreeType.name} tree type`,
        input: 'select',
        inputOptions,
        inputPlaceholder: 'Select a tree type',
        // confirmButtonColor: '#4b6b41',
        showCancelButton: true,
        inputValidator: (treeTypeId) => {
          return new Promise((resolve) => {
            resolve()
// Todo: check this
            let areaTreeTypesIds = Object.values(this.area.last_checkpoint.data).map(oldTreeType => oldTreeType.id);
            if (areaTreeTypesIds.includes(parseInt(treeTypeId))) {
              resolve(`You can't repeat a tree type in the same area`)
            } else {
              resolve()
            }
          })
        }
      })

      if (treeTypeId) {
        TreeType.getTreeTypes(treeTypeId)
          .then((res) => {
            // const math = create(all);

            let treeType = res.data;


            if (this.area.last_checkpoint_id) {

              let lastCheckpointTreeTypeData = this.area.treeTypes[i];
              lastCheckpointTreeTypeData.id = parseInt(treeTypeId);
              lastCheckpointTreeTypeData.reference = treeType.reference;
              lastCheckpointTreeTypeData.name = treeType.name;
              lastCheckpointTreeTypeData.densityKg = treeType.density_kg;
              lastCheckpointTreeTypeData.practicalFormula = treeType.practical_formulas;
              lastCheckpointTreeTypeData.theoreticalFormula = treeType.time_formula;

              renderTreeTypeData(this.area.treeTypes, lastCheckpointTreeTypeData.datetime).then((lastCheckpoint) => {
                // lastCheckpoint.data.treeTypes = {...lastCheckpoint.data.treeTypes[i]};
                // delete lastCheckpoint.data.treeTypes[i];

                Checkpoint.putCheckpoints(lastCheckpoint).then(() => {
                  Swal.fire(
                    'Done!',
                    `Tree type successfully changed from ${oldTreeType.name} to ${treeType.name}`,
                    'success'
                  )
                  this.loadArea();
                })
              })


            }
          })
      }
    },
    async delAdditionality(i) {


      Swal.fire({
        title: 'Are you sure?',
        text: "You will delete an additionality",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.isConfirmed) {


          console.log({area3: this.area})
          console.log({i})


          Swal.fire({
            title: 'Processing',
            html: 'Wait a moment...',
            didOpen: () => {
              Swal.showLoading()
            },
            allowOutsideClick: () => !Swal.isLoading()
          })

          let additionalitiesArr = this.area.additionalities.splice(i, 1);
          console.log({additionalitiesArr})
          console.log({additionalitiesArr2: this.area.additionalities})
          let additionalities = this.area.additionalities.map(r => r.id)
          console.log({splice: additionalities})
          Area.putAreas({
            id: this.area.id,
            additionalities,
          }).then(() => {
            Swal.fire(
              'Deleted successfully',
              `Additionality deleted!`,
              'success');

            this.loadArea();

          }).catch((e) => {
            console.log('e', e)
            Swal.fire(
              'Error',
              e.message,
              'error');
          })

        }
      })

    },
    async changeOwner() {

      let inputOptions = this.users.reduce((json, val) => {
        json[val.id] = val.name || '';
        return json;
      }, {})

      const res = await Swal.fire({
        title: `Change ${this.area.user_name || ''} owner`,
        input: 'select',
        inputOptions,
        inputPlaceholder: 'Select an owner',
        // confirmButtonColor: '#4b6b41',
        showCancelButton: true
      })

      // if (users_permissions_user) {
      if (res.isConfirmed)
        Area.putAreas({
          id: this.area.id,
          users_permissions_user: res.value || null
        }).then(() => {
          Swal.fire(
            'Done!',
            `Owner successfully changed`,
            'success'
          )
          this.loadArea();
        })

      // }
    },
    async addAdditionality() {


      const {value: additionality} = await Swal.fire({
        title: "Select additionality type",
        input: "select",
        inputOptions: {

          fertilizing: "Fertilizing",
          thinning: "Thinning",
          postponedFinalCut: "Postponed final cut"
        },
        inputPlaceholder: "Select additionality",
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve();
            } else {
              resolve("You need to select one");
            }
          });
        }
      });


      if (additionality == 'fertilizing') {


        let fertilizings = (await Fertilizings.getFertilizings()).data

        console.log({fertilizings})
        let inputOptions = {}

        for (let data of fertilizings) {
          inputOptions[data.id] = data.fertilizing;
        }

        const {value: fertilizing} = await Swal.fire({
          title: "Select fertilizing type",
          input: "select",
          inputOptions,
          inputPlaceholder: "Select fertilizing",
          showCancelButton: true,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value) {
                resolve();
              } else {
                resolve("You need to select one");
              }
            });
          }
        });
        console.log({fertilizing})
        const {value: date} = await Swal.fire({
          title: "Select additionality start date",
          input: "date",
          didOpen: () => {
            // const today = (new Date()).toISOString();
            // Swal.getInput().min = today.split("T")[0];
          }
        });

        const startingDt = moment(date).format('YYYY-MM-DD');
        const defaultYear = parseInt(moment(date).format('YYYY')) + 1;

        const {value: year} = await Swal.fire({
          title: "Select additionality start date",
          input: "number",
          inputValue: defaultYear,
        });

        // return;

        // if (fertilizings == "AMERICA") {

        console.log(this.allTreeTypes, 'this.allTreeTypes')

        let areaLastCheckpointData = this.area.last_checkpoint.theoretical_stats;
        let areaLastCheckpointTreeTypesData = this.area.last_checkpoint.data.treeTypes;
        console.log({areaLastCheckpointData})
        console.log({areaaaa: this.area})

        let yearIndex = 0;
        for (const i in areaLastCheckpointData) {
          console.log("areaLastCheckpointData[i].year", areaLastCheckpointData[i].year)
          console.log("year", year)
          if (areaLastCheckpointData[i].year == year) {
            console.log('YEAAAAAAAAAAAAAAAAAAAAHHHHHHHHHHHHHHHHHHHHHHHHHHHH', i)
            yearIndex = parseInt(i);
            break;
          }
        }

        console.log({yearIndex})

        for (const treeType of areaLastCheckpointTreeTypesData) {

          let res = await TreeType.getTreeTypes(treeType.id)
          const dbTreeType = res.data;

          let lengthYears = 0;
          let yearValues = {};
          for (const dbTreeTypeElement of dbTreeType.tree_types_fertilizings) {
            console.log({dbTreeTypeElement})
            if (dbTreeTypeElement.Factors.length > lengthYears) lengthYears = dbTreeTypeElement.Factors.length;
            for (const factor of dbTreeTypeElement.Factors) {
              console.log({factor})
              if (yearValues[factor.year + yearIndex])
                yearValues[factor.year + yearIndex] += factor.factor || 0;
              else
                yearValues[factor.year + yearIndex] = factor.factor || 0;
            }

          }


          console.log({yearValues})
          console.log({dbTreeType})

          let additionalities = []
          let accumulatedAdditionality = 0;
          for (const i in treeType.theoreticalStats) {
            if (yearValues[i] != null) {
              console.log(yearValues[i], 'yearValues[i]')
              const theoreticalStat = treeType.theoreticalStats[i]
          console.log({theoreticalStat})
              accumulatedAdditionality += theoreticalStat.theoreticalIncrement * (yearValues[i] || 0)
              let additionality = {
                year: additionalities.length,
                value: accumulatedAdditionality
              }

              additionalities.push(additionality)

            }
          }

          console.log({additionalities})

          Swal.fire({
            title: 'Processing',
            html: 'Wait a moment...',
            didOpen: () => {
              Swal.showLoading()
            },
            allowOutsideClick: () => !Swal.isLoading()
          })

          await Additionalities.postAdditionalities({
            type: 'Ferlitizing',
            area: this.area.id,
            starting_dt: startingDt,
            starting_year: year,
            end_year: year + lengthYears,
            values: additionalities
          })


        }


        // CheckpointTreeTypes.postCheckpointTreeTypesFertilizing({
        //   id: 1,
        //   checkpoint_tree_type_id: 153,
        //   'tree-types-fertilizing_id': 1,
        // })
        //}

      } else if (additionality == 'thinning') {


        // let fertilizings = (await Fertilizings.getFertilizings()).data


        const {value: date} = await Swal.fire({
          title: "Select additionality start date",
          input: "date",
          didOpen: () => {
            // const today = (new Date()).toISOString();
            // Swal.getInput().min = today.split("T")[0];
          }
        });


        const startingDt = moment(date).format('YYYY-MM-DD');
        const defaultYear = parseInt(moment(date).format('YYYY')) + 1;

        const {value: year} = await Swal.fire({
          title: "Select additionality start date",
          input: "number",
          inputValue: defaultYear,
        });

        let areaLastCheckpointData = this.area.last_checkpoint.theoretical_stats;

        console.log({area: this.area})
        console.log({areaLastCheckpointData})

        let thinningPercentFactor = 1 - (1000 / (this.area.qty / this.area.area_ha));
        let additionalThinningPercentFactor = 0.20;
        console.log({thinningPercentFactor})
        console.log({additionalThinningPercentFactor})
        console.log({areaLastCheckpointData})


        let additionalities = []
        for (const i in areaLastCheckpointData) {
          console.log('----------------------------------------------------------------')
          console.log({year})
          console.log({areaLastCheckpointDataYear: areaLastCheckpointData[i].year})
          console.log({areaLastCheckpointDataTones: areaLastCheckpointData[i].totalTonCCO2})
          console.log({areaLastCheckpointDataValues: areaLastCheckpointData[i].totalTonCCO2 * additionalThinningPercentFactor})
          console.log({areaLastCheckpointDataBaselineValues: -(areaLastCheckpointData[i].totalTonCCO2 * thinningPercentFactor)})
          if (areaLastCheckpointData[i].year >= year) {

            console.log({areaLastCheckpointDataINDEX: i})

            let baselineValue = (areaLastCheckpointData[i].theoreticalIncrement * thinningPercentFactor);
            additionalities.push({
              year: additionalities.length,
              value: baselineValue * additionalThinningPercentFactor,
              baseline_value: -baselineValue,
            })
          }
        }

        console.log({additionalities})

        Swal.fire({
          title: 'Processing',
          html: 'Wait a moment...',
          didOpen: () => {
            Swal.showLoading()
          },
          allowOutsideClick: () => !Swal.isLoading()
        })

        await Additionalities.postAdditionalities({
          type: 'Thinning',
          area: this.area.id,
          starting_dt: startingDt,
          starting_year: year,
          end_year: year + areaLastCheckpointData.length,
          values: additionalities
        })


      } else if (additionality == 'postponedFinalCut') {


        // let fertilizings = (await Fertilizings.getFertilizings()).data


        const {value: date} = await Swal.fire({
          title: "Select additionality start date",
          input: "date",
          didOpen: () => {
            // const today = (new Date()).toISOString();
            // Swal.getInput().min = today.split("T")[0];
          }
        });


        const startingDt = moment(date).format('YYYY-MM-DD');
        const defaultYear = parseInt(moment(date).format('YYYY')) + 1;

        const {value: year} = await Swal.fire({
          title: "Select additionality start date",
          input: "number",
          inputValue: defaultYear,
        });

        let areaLastCheckpointData = this.area.last_checkpoint.theoretical_stats;


        let additionalities = []
        for (const i in areaLastCheckpointData) {

          if (areaLastCheckpointData[i].year >= year) {

            console.log({areaLastCheckpointDataINDEX: i})

            additionalities.push({
              year: additionalities.length,
              value: areaLastCheckpointData[i].totalTonCCO2,
              baseline_value: - areaLastCheckpointData[i].totalTonCCO2,
            })
          }
        }

        console.log({additionalities})

        Swal.fire({
          title: 'Processing',
          html: 'Wait a moment...',
          didOpen: () => {
            Swal.showLoading()
          },
          allowOutsideClick: () => !Swal.isLoading()
        })

        await Additionalities.postAdditionalities({
          type: 'Postponed final cut',
          area: this.area.id,
          starting_dt: startingDt,
          starting_year: year,
          end_year: year + areaLastCheckpointData.length,
          values: additionalities
        })


      }


      this.renderStatsTable();
      Swal.fire(
        'Done!',
        `Additionality successfully added`,
        'success'
      )


    },
    async withdraw() {


      if (this.balance) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You will transfer all the ZCO2 tokens from the forest wallet your connected wallet",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await Swal.fire({
              title: 'Enter the receiver wallet address',
              input: 'text',
              inputLabel: 'Wallet address',
              inputValue: this.getActiveAccount,
              showCancelButton: true,
              inputValidator: (value) => {
                if (!value) {
                  return 'You need to write a wallet address!'
                }
              }
            })

            if (res.isConfirmed && res.value) {

              const amount = await Swal.fire({
                title: 'Enter the amount of ZCO2',
                input: 'number',
                inputLabel: 'ZCO2 amount',
                inputValue: this.balance.toFixed(4),
                showCancelButton: true,
                inputValidator: (value) => {
                  if (parseFloat(value) > this.balance) {
                    return `Max amount is ${this.balance}`;
                  }
                }
              })


              if (amount.isConfirmed) {

                if (!parseFloat(amount.value)) {
                  Swal.fire(
                    'Empty amount',
                    `Amount can not be empty`,
                    'error'
                  )
                  return
                }


                Swal.fire({
                  title: 'Processing',
                  html: 'Waiting for confirmations',
                  didOpen: () => {
                    Swal.showLoading()
                  },
                  allowOutsideClick: () => !Swal.isLoading()
                })
                Area.withdraw(this.area.id, {
                  walletAddress: res.value,
                  amount: parseFloat(amount.value),
                }).then((data) => {
                  Swal.fire(
                    'Withdraw done successfully',
                    `You've successfully withdrawn your ZCO2 tokens.<br><a href="${this.config.tx_url_template.replace('{tx}', data.data.hash)}" target="_blank">Go to explorer</a>`,
                    'success');

                  this.loadArea();

                }).catch((e) => {
                  console.log('e', e)
                  Swal.fire(
                    'Error',
                    e.message,
                    'error');
                })

              }

            }
          }
        })
      } else {
        Swal.fire(
          'No balance!',
          `This forest balance is empty`,
          'error'
        )
      }


      // }
    },
    textShort(txt) {
      return textShortener(txt)
    }
  }
}
</script>

<style scoped>

</style>
