import Ajax from './Ajax.js'

const Fertilizing = {
  getFertilizings: (id = null) => {
    let uri = id !== null
      ? '/fertilizings' + '/' + id
      : '/fertilizings'
    return Ajax.get(uri)
  },

  postFertilizings: (params) => {
    return Ajax.post('/fertilizings', params)
  },

  putFertilizings: (params) => {
    return Ajax.put('/fertilizings/' + params.id, params)
  },

  delFertilizings: (id) => {
    return Ajax.delete('/fertilizings/' + id)
  }
}

export default Fertilizing
