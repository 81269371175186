import Ajax from './Ajax.js'

const Additionality = {
  getAdditionalities: (id = null) => {
    let uri = id !== null
      ? '/additionalities' + '/' + id
      : '/additionalities'
    return Ajax.get(uri)
  },

  postAdditionalities: (params) => {
    return Ajax.post('/additionalities', params)
  },

  putAdditionalities: (params) => {
    return Ajax.put('/additionalities/' + params.id, params)
  },

  delAdditionalities: (id) => {
    return Ajax.delete('/additionalities/' + id)
  }
}

export default Additionality
