/**
 *
 * @param text
 * @param length
 * @returns {string|*}
 */
const textShortener = (text, length = 6) => {
  if (text.length > length * 2) {
    return text.substr(0, length) + '...' + text.substr(-length);
  } else {
    return text;
  }
}


export {textShortener}
